<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row class="my-3">
      <v-col>
        <v-btn
          color="primary"
          @click.stop="createDialog()"
        >
          Nuevo Plan
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
        offset-md="6"
        offset-lg="8"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          light
        >
          <template v-slot:[`item.status`]="{ item }">
            <span :class="item.status ? 'success--text' : 'error--text' ">
              <b>
                {{ item.status ? 'Activo' : 'Inactivo' }}
              </b>
            </span>
          </template>

          <template v-slot:[`item.verified`]="{ item }">
            <span :class="item.verified ? 'success--text' : 'error--text' ">
              <b>
                {{ item.verified ? 'Verificado' : 'Sin Verificar' }}
              </b>
            </span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              :color="item.status ? 'error' : 'success' "
              @click="setStatus(item.id, !item.status)"
            >
              {{ item.status ? 'mdi-close-circle' : 'mdi-check-circle' }}
            </v-icon>
            <v-icon
              @click="updateDialog(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogs.create"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="font-title">
          Nuevo Plan
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="forms.create.user"
                label="Selecciona el usuario"
                type="text"
                :items="users"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="forms.create.description"
                label="Descripcion del plan"
                rows="3"
              />
            </v-col>
            <v-col lg="6">
              <date-picker
                v-model="forms.create.start"
                label="Fecha de inicio"
              />
            </v-col>
            <v-col lg="6">
              <date-picker
                v-model="forms.create.end"
                label="Fecha de finalizacion"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p
                v-for="(error, i) in forms.create.errors"
                :key="error-i"
                class="error--text"
              >
                {{ error }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="create"
          >
            Crear
          </v-btn>
          <v-btn
            color="secondary"
            @click="dialogs.create = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogs.update"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="font-title">
          Actualizar Plan
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="forms.update.description"
                label="Descripcion del plan"
                rows="3"
              />
            </v-col>
            <v-col lg="6">
              <date-picker
                v-model="forms.update.start"
                label="Fecha de inicio"
              />
            </v-col>
            <v-col lg="6">
              <date-picker
                v-model="forms.update.end"
                label="Fecha de finalizacion"
              />
            </v-col>
            <v-col lg="6">
              <date-picker
                v-model="forms.update.portions_next_update"
                label="Actualizacion de porciones"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p
                v-for="(error, i) in forms.update.errors"
                :key="error-i"
                class="error--text"
              >
                {{ error }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="update"
          >
            Actualizar
          </v-btn>
          <v-btn
            color="secondary"
            @click="dialogs.update = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { isEmpty } from 'validator'
  export default {
    data () {
      return {
        headers: [
          { text: 'Usuario', value: 'user.name' },
          { text: 'Descripcion', value: 'description' },
          { text: 'Inicio', value: 'start' },
          { text: 'Fin', value: 'end' },
          { text: 'Estado', value: 'status' },
          { text: 'Acciones', value: 'action' },
        ],
        items: [],
        users: [],
        search: null,
        dialogs: {
          create: false,
          update: false,
        },
        forms: {
          create: {
            user: null,
            description: null,
            start: null,
            end: null,
            errors: [],
          },
          update: {
            id: null,
            description: null,
            start: null,
            end: null,
            portions_next_update: null,
            errors: [],
          },
        },
      }
    },
    async mounted () {
      await this.getItems()
      await this.getUsers()
    },
    methods: {
      async getItems () {
        this.items = await this.$api.UserPlan.list()
      },
      async getUsers () {
        const users = await this.$api.User.list()
        this.users = users.map(x => {
          return { text: x.name, value: x.id }
        })
      },
      validate (type) {
        let form = null
        if (type === 'create') {
          form = this.forms.create
        } else if (type === 'update') {
          form = this.forms.update
        }
        form.errors.splice(0, form.errors.length)
        if (isEmpty(`${form.user}`)) {
          form.errors.push('Debes seleccionar a un usuario')
        }
        if (isEmpty(`${form.start}`)) {
          form.errors.push('Debes seleccionar la fecha de inicio')
        }
        if (isEmpty(`${form.end}`)) {
          form.errors.push('Debes seleccionar la fecha de finalizacion')
        }
        return form?.errors?.length === 0
      },
      async create () {
        const valid = this.validate('create')
        if (!valid) {
          return
        }
        const form = this.forms.create
        const payload = {
          user_id: form.user,
          description: form.description,
          start: form.start,
          end: form.end,
          portions_next_update: form.start,
        }
        try {
          const response = await this.$api.UserPlan.create(payload)
          this.items.push(response)
          this.dialogs.create = false
        } catch (err) {
          const response = err.response
          const data = response.data
          form.errors.splice(0, form.errors.length)
          if (response.status === 400) {
            if ('msg' in data && data.msg === 'Already exists') {
              form.errors.push('El correo ya esta registrado')
            }
          }
        }
      },
      createDialog () {
        const form = this.forms.create
        form.user = ''
        form.description = ''
        form.start = ''
        form.end = ''
        this.dialogs.create = true
      },
      updateDialog (item) {
        const form = this.forms.update
        form.id = item.id
        form.description = item.description
        form.start = item.start
        form.end = item.end
        form.portions_next_update = item.portions_next_update
        this.dialogs.update = true
      },
      async update () {
        const valid = this.validate('update')
        if (!valid) {
          return
        }
        const form = this.forms.update
        const payload = {
          description: form.description,
          start: form.start,
          end: form.end,
          portions_next_update: form.portions_next_update,
        }
        try {
          const response = await this.$api.UserPlan.update(form.id, payload)
          const index = this.items.map(x => x.id).indexOf(form.id)
          this.items.splice(index, 1, response)
          this.dialogs.update = false
        } catch (err) {

        }
      },
      async setStatus (id, status) {
        const response = await this.$api.UserPlan.setStatus(id, status)
        const index = this.items.map(x => x.id).indexOf(id)
        this.items.splice(index, 1, response)
      },
    },
  }
</script>
